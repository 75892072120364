import { BaseInput } from '@kit/inputs/base-input';
import { EmailInputProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const EmailInput: React.FC<EmailInputProps> = props => {
  return _jsx(BaseInput //
  /**
   * Since Firefox has a bug with trailing spaces https://bugzilla.mozilla.org/show_bug.cgi?id=849611 we keep 'text' type for email fields
   * input with type="text" is used to fix firing of the 'change' event
   * when 'space' symbol was typed.
   * input with type="email" trims text and doesn't fire 'change' event
   * when 'space' symbol was typed.
   */, {
    type: "text",
    ...props
  });
};