import { useCallback, useEffect, useRef } from 'react';
import { UseGetMounted } from './types';
export const useGetMounted: UseGetMounted = () => {
  const mountedRef = useRef(false);
  const getMounted = useCallback(() => mountedRef.current, []);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return getMounted;
};