import { AppFCC } from '@types';
import { WithErrorStyled } from './styled';
import { WithErrorProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const WithError: AppFCC<WithErrorProps> = props => {
  const {
    children,
    className
  } = props;
  return _jsx(WithErrorStyled, {
    className: className,
    children: children
  });
};