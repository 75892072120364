import { BaseNetError } from '@errors/base-net-error';
import { accountHandleErrorBodyErrors } from '@actions/account/account-handle-error-body-errors';
import { AccountHandleAccountError } from './types';
export const accountHandleError: AccountHandleAccountError = payload => dispatch => {
  const {
    error,
    provider,
    email,
    password,
    code
  } = payload;
  if (!(error instanceof BaseNetError)) {
    throw error;
  }
  const errors = error.getOtherErrors();
  dispatch(accountHandleErrorBodyErrors({
    errors,
    provider,
    email,
    password,
    code
  }));
};