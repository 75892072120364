import { useCallback, useEffect } from 'react';
import { IExternalAuthEventHandlerCallback } from '@auth/external-auth-event-handler';
import { getTimezoneId, getTimezoneOffset } from '@helpers/date';
import { isDesktop } from '@helpers/geometry';
import { computeAuthVisitData } from '@helpers/logging/analytics/visit-event';
import { AppFCC, CULTURE, PROVIDER } from '@types';
import { geometryGetType } from '@selectors/geometry';
import { useExternalAuthEventHandler } from '@hooks/auth/external-auth-event-handler';
import { useExternalAuthWindow } from '@hooks/auth/external-auth-window';
import { useNavigator } from '@hooks/routing';
import { useStorage } from '@hooks/storage';
import { useTypedSelector } from '@hooks/store';
import { ButtonBase } from '@kit/buttons/button-base';
import { ExternalAuthWidgetButtonsStyled, ExternalAuthWidgetFacebookIconStyled, ExternalAuthWidgetGoogleIconStyled, ExternalAuthWidgetVkIconStyled } from './styled';
import { ExternalAuthWidgetOpenAuthWindow, ExternalAuthWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ExternalAuthWidget: AppFCC<ExternalAuthWidgetProps> = props => {
  const {
    className,
    iconClassName,
    roleType,
    onSuccess
  } = props;
  const storage = useStorage();
  const navigator = useNavigator();
  const externalAuthWindow = useExternalAuthWindow();
  const externalAuthEventHandler = useExternalAuthEventHandler();
  const geometryType = useTypedSelector(geometryGetType);
  const openAuthWindow: ExternalAuthWidgetOpenAuthWindow = useCallback(provider => () => {
    externalAuthWindow.open({
      provider,
      roleType,
      culture: CULTURE.RU,
      timezoneId: getTimezoneId(),
      timezoneOffset: getTimezoneOffset(),
      isDesktop: isDesktop(geometryType),
      restoreAccount: false,
      ...computeAuthVisitData({
        storage,
        navigator,
        geometryType
      })
    });
  }, [externalAuthWindow, geometryType, navigator, roleType, storage]);
  const handleEvent: IExternalAuthEventHandlerCallback = useCallback(({
    data
  }) => {
    if (data) {
      onSuccess?.();
    }
  }, [onSuccess]);
  useEffect(() => {
    const usubscribe = externalAuthEventHandler.subscribe(handleEvent);
    return usubscribe;
  }, [externalAuthEventHandler, handleEvent]);
  return _jsxs(ExternalAuthWidgetButtonsStyled, {
    className: className,
    children: [_jsx(ButtonBase, {
      onClick: openAuthWindow(PROVIDER.FB),
      children: _jsx(ExternalAuthWidgetFacebookIconStyled, {
        className: iconClassName
      })
    }), _jsx(ButtonBase, {
      onClick: openAuthWindow(PROVIDER.VK),
      children: _jsx(ExternalAuthWidgetVkIconStyled, {
        className: iconClassName
      })
    }), _jsx(ButtonBase, {
      onClick: openAuthWindow(PROVIDER.GOOGLE),
      children: _jsx(ExternalAuthWidgetGoogleIconStyled, {
        className: iconClassName
      })
    })]
  });
};