import { AppFCC } from '@types';
import { TabsStyled } from './styled';
import { TabsProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Tabs: AppFCC<TabsProps> = props => {
  const {
    className,
    children
  } = props;
  return _jsx(TabsStyled, {
    className: className,
    children: children
  });
};