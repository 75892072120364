import { useCallback, useRef, useState } from 'react';
import { useGetMounted } from '@hooks/get-mounted';
import { UsePending, UsePendingSetPending } from './types';
export const usePending: UsePending = defaultPending => {
  const getMounted = useGetMounted();
  const pendingRef = useRef(defaultPending);
  const [_pending, _setPending] = useState(defaultPending);
  const setPending: UsePendingSetPending = useCallback(pending => {
    if (!getMounted()) {
      return;
    }
    _setPending(pending);
    pendingRef.current = pending;
  }, [getMounted]);
  const getPending = useCallback(() => pendingRef.current,
  //
  []);
  return [_pending, setPending, getPending];
};