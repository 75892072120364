import { useCallback } from 'react';
import { TabsTabStyled } from './styled';
import { TabsTabProps, TabsTabValue } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function TabsTab<V extends TabsTabValue>(props: TabsTabProps<V>): React.ReactElement<TabsTabProps<V>> {
  const {
    className,
    children,
    value,
    isActive,
    disabled,
    onClick
  } = props;
  const click = useCallback(() => onClick?.((value as V)),
  //
  [onClick, value]);
  return _jsx(TabsTabStyled //
  , {
    className: className,
    isActive: isActive,
    disabled: disabled,
    onClick: click,
    children: children
  });
}