import { AppFCC } from '@types';
import { BaseInput } from '@kit/inputs/base-input';
import { PasswordInputProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const PasswordInput: AppFCC<PasswordInputProps> = props => {
  return _jsx(BaseInput //
  , {
    type: "password",
    ...props
  });
};